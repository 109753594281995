import {
    GET_ORG_LIST,ERROR_ORG_LIST,SUBMIT_SUCCESS,SUBMIT_FAIL,GET_SELECTED_DEVICE_ORG_DATA, ERROR_SELECTED_DEVICE_ORG_DATA, CLEAR_SELECTED_DEVICE_ORG_DATA
} from "./constants";

const initialState = {
    id: null,
    name: null,
    loading: true,
    error: '',
    orgData: [],
    orgList:[],
    count: 0,
    limit: 0,
    offset: 0,
    isError:false,
    result: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SELECTED_DEVICE_ORG_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                orgData: payload.orgData,
                count: payload.count,
                limit: payload.limit,
                offset: payload.offset,
                loading: false,
                isError:false,
                error: ''
            }
        case ERROR_SELECTED_DEVICE_ORG_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                orgData: [],
                count: 0
            }
        case GET_ORG_LIST:
            return {
                ...state,
                orgList: payload.orgList,
                limit: payload.limit,
                offset: payload.offset,
                loading: false,
                isError:false,
                error: '',
                result:true,
            }
        case ERROR_ORG_LIST:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                orgList: [],
                count: 0,
                result:false,
            }
        case SUBMIT_FAIL:
                return {
                    ...state,
                    result: false
                }
        case SUBMIT_SUCCESS:
                return {
                    ...state,
                    result: true
                }
        case CLEAR_SELECTED_DEVICE_ORG_DATA:
            return initialState
        default:
            return state;
    }
}