import React, { Suspense, useEffect } from 'react';
import GlobalStyle from './global-styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { adminRoot } from './constants/defaultValues';
import ProtectedRoute from './helper/authHelper';
import { UserRole } from './helper/Utils';
import setAuthToken from './helper/setAuthToken';
import store from './store';
import { LOGOUT } from './Container/Login/constants';
import { loadUser } from './Container/Login/actions';


const ViewHome = React.lazy(() =>
  import('./Views/home')
);
const ViewApp = React.lazy(() =>
  import('./Views/app/')
);
const ViewAdmin = React.lazy(() =>
  import('./Views/admin')
);
const ViewError = React.lazy(() =>
  import('./Views/error')
);
const ViewUnauthorized = React.lazy(() =>
  import('./Views/unauthorized')
);
const ViewSuccess = React.lazy(() =>
  import('./Views/success')
);

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
   // if (!localStorage.token) store.dispatch({ type: LOGOUT });
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);
  return (
    <div className="h-100">
      <>
        <Suspense fallback={<div className="loading" />}>
          <Router>
            <Switch>
              <Redirect exact from={`/`} to={`/admin`} />
              <ProtectedRoute
                path={adminRoot}
                component={ViewApp}
                roles={[UserRole.superAdmin,UserRole.admin, UserRole.editor,UserRole.member,UserRole.electrical,UserRole.assembly]}
              />
              <Route
                path="/admin"
                render={(props) => <ViewAdmin {...props} />}
              />
              <Route
                path="/error"
                exact
                render={(props) => <ViewError {...props} />}
              />
              <Route
                path="/success"
                exact
                render={(props) => <ViewSuccess {...props} />}
              />
              <Route
                path="/unauthorized"
                exact
                render={(props) => <ViewUnauthorized {...props} />}
              />
              <Route
                path="/"
                exact
                render={(props) => <ViewHome {...props} />}
              />
              <Redirect to="/error" />
            </Switch>
          </Router>
        </Suspense>
      </>
      <GlobalStyle />
    </div>
  );
}

export default App;
