import { constant } from 'lodash'
import { SUBMIT_SUCCESS, SUBMIT_FAIL,GET_EXCEL_DATA,ERROR_GET_EXCEL_DATA } from './constants'

const initialState = {
    result: false,
    electrical_System:[]
}

export default function (state = initialState, action) {
    const { type,payload } = action;
    switch (type) {
        case GET_EXCEL_DATA:
            return{
                ...state,
                electrical_System :payload.electricalSystem
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                result: false
            }
        case SUBMIT_SUCCESS:
            return {
                ...state,
                result: true
            }
        default:
            return state;
    }
}