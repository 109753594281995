import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    background-color: #f5f5f5;
  }

  body {
    font-family: 'Helvetica';
  }

  body.fontLoaded {
    font-family: 'Helvetica';
  }

  #wrapper {
    display: flex;
  }
  #wrapper #content-wrapper {
    background-color: #E9ECF0;
    width: 100%;
    overflow-x: hidden;
    }

    #wrapper #content-wrapper #content {
        flex: 1 0 auto;
    }
  p,
  label {
    font-family: Helvetica;
    line-height: 1.5em;
  }
`;

export default GlobalStyle;
