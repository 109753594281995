import { SUBMIT_SUCCESS, SUBMIT_FAIL,GET_EXCEL_DATA,ERROR_GET_EXCEL_DATA } from './constants'

const initialState = {
    result: false,
    compressor_id :'',
    sealed_system_id : '',
    sealed_System:[],
}

export default function (state = initialState, action) {
    const { type ,payload } = action;
    switch (type) {
        case GET_EXCEL_DATA:
            return{
                ...state,
                sealed_System :payload.sealedSystem,
                compressor_id : payload.compressor_id,
                sealed_system_id :payload.sealed_system_id,
            };
        case SUBMIT_SUCCESS:
            return {
                ...state,
                result: true
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                result: false
            }
        default:
            return state;
    }
}