import {
    GET_SELECTED_DEVICE_TRIP_DATA, ERROR_SELECTED_DEVICE_TRIP_DATA, CLEAR_SELECTED_DEVICE_TRIP_DATA ,GET_FILE
} from "./constants";

const initialState = {
    
                id: null,
                name: null,
                loading: true,
                loadingTrips:true,
                loadingRawData:true,
                isError:false,
                error: '',
                devices: [],
                devRecordsTrips: [],
                trips: [],
                rawData: [],
                active: 0,
                count: 0,
                limit: 0,
                offset: 0,
                datafile:''
               // ...state,
}

export default function (state = initialState, action) {
    const { type, payload ,headers } = action;
    switch (type) {
        case GET_SELECTED_DEVICE_TRIP_DATA:
            return {
                ...state,
                id: payload.id,
                name: payload.name,
                trips: payload.trips,
                limit: payload.limit,
                offset: payload.offset,
                count: payload.count,
                loading: false,
                isError:false,
                error: ''
            }
        case ERROR_SELECTED_DEVICE_TRIP_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                trips: [],
                count: 0
            }
            case GET_FILE:
                return{
                    ...state,
                    error: '',
                    devices: [],
                    loading: false,
                    count: 0,
                    datafile:headers['content-disposition'].split('filename=')
                }
        case CLEAR_SELECTED_DEVICE_TRIP_DATA:
            return initialState
        default:
            return state;
    }
}