//TRIPS
export const GET_TRIPS = 'GET_TRIPS';
export const ERROR_TRIPS = 'ERROR_TRIPS';

export const GET_TRIPS_DETAILS = 'GET_TRIPS_DETAILS';
export const ERROR_TRIPS_DETAILS = 'ERROR_TRIPS_DETAILS';
//TRIP DATA GRRAPH
export const GET_CURRENT_TRIP_GRAPH = 'GET_CURRENT_TRIP_GRAPH';
export const ERROR_CURRENT_TRIP_GRAPH = 'ERROR_CURRENT_TRIP_GRAPH';
export const CLEAR_CURRENT_TRIP_GRAPH = 'CLEAR_CURRENT_TRIP_GRAPH';
//TRIP EVENTS
export const GET_CURRENT_TRIP_EVENTS = 'GET_CURRENT_TRIP_EVENTS';
export const ERROR_CURRENT_TRIP_EVENTS = 'ERROR_CURRENT_TRIP_EVENTS';
//DIREACTION
export const GET_DEVICE_DIRECTION = 'GET_DEVICE_DIRECTION';
export const ERROR_DEVICE_DIRECTION ='ERROR_DEVICE_DIRECTION';
export const CLEAR_DEVICE_DIRECTION = 'CLEAR_DEVICE_DIRECTION';
//CLEAR LIVE TRACKING
export const CLEAR_TRIPS = 'CLEAR_TRIPS';