import { GET_TRIPS, ERROR_TRIPS, CLEAR_TRIPS, GET_CURRENT_TRIP_GRAPH, ERROR_CURRENT_TRIP_GRAPH, GET_TRIPS_DETAILS, ERROR_TRIPS_DETAILS, ERROR_CURRENT_TRIP_EVENTS, GET_CURRENT_TRIP_EVENTS, ERROR_DEVICE_DIRECTION, GET_DEVICE_DIRECTION, CLEAR_DEVICE_DIRECTION } from "./constants";
const initialState = {
    loading: true,
    active: null,
    trips: [],
    tripDetails: [],
    directionLoading: true,
    error: '',
    DireactionError: '',
    inactiveTrip: [],
    gps: [],
    lineGraph: [],
    tripData: [],
    events: [],
    limit: null,
    offset: null,
    gpsWayPoints: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_TRIPS:
            return {
                ...state,
                error: '',
                limit: payload.limit,
                offset: payload.offset,
                active: payload.active,
                trips: payload.trip_data,
                gps: payload.gpsdata,
                loading: false,
               
            }
        case GET_DEVICE_DIRECTION:
            return {
                ...state,
                directionLoading: false,
                gpsWayPoints: payload,
                error: '',
            }
        case CLEAR_DEVICE_DIRECTION:
            return {
                ...state,
                directionLoading: true,
                gpsWayPoints:[],
                error: '',
            }
        case GET_TRIPS_DETAILS:
            return {
                ...state,
                tripDetails : payload.trip_details,
                active : payload.active,
                directionLoading: true,
                error: '',
            }
        case GET_CURRENT_TRIP_GRAPH:
            return {
                ...state,
                lineGraph: payload.lineGraphLookup,
                tripData: payload.response,
                loading: false,
                directionLoading: true,
                error: '',
            }
        case GET_CURRENT_TRIP_EVENTS:
            return {
                ...state,
                events: payload.data, 
                directionLoading: true,
            }
        case ERROR_TRIPS:
            return {
                ...state,
                error: payload,
                loading: false,
                trips: [],
                tripDetails: [],
            }
        case ERROR_TRIPS_DETAILS:
        case ERROR_CURRENT_TRIP_GRAPH:
        case ERROR_CURRENT_TRIP_EVENTS:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case ERROR_DEVICE_DIRECTION:
            return {
                ...state,
                DireactionError: payload,
                error:payload,
                loading: false,
            }
        case CLEAR_TRIPS:
            return initialState
        default:
            return state;
    }
}