
import React from 'react';
import { connect } from 'react-redux';
import {
    Route,
    Redirect,
} from 'react-router-dom';
import { isAuthGuardActive } from '../constants/defaultValues';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, currentUser, roles = undefined,
    auth: { isAuthenticated,token },
    ...rest
}) => {
    const setComponent = (props) => {
        if (isAuthGuardActive) {
           if (isAuthenticated) {
                if (roles) {
                    if (roles.includes(currentUser.role)) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect
                            to={{
                                pathname: '/unauthorized',
                                state: { from: props.location },
                            }} />
                    }
                } else {
                    return <Component {...props} />;
                }
            }
             else if(token === null){
                return <Redirect
                    to={{
                        pathname: '/admin/login',
                        state: { from: props.location },
                    }} />
            }
        } else {
            return <Component {...props} />;
        }

    }

    return (
        <Route
            {...rest}
            render={setComponent}
        />
    );
}
ProtectedRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
    auth: state.auth
})

export default connect(mapStateToProps, null)(ProtectedRoute)
