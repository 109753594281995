export const getCurrentUser = () => {
    let user = null;
    try {
      user = localStorage.getItem('gogo_current_user') != null ? JSON.parse(localStorage.getItem('gogo_current_user')) : null;
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
      user = null;
    }
    return user;
  }
  
  export const setCurrentUser = (user) => {
    try {
      if (user) {
        localStorage.setItem('gogo_current_user', JSON.stringify(user))
      } else {
        localStorage.removeItem('gogo_current_user')
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
    }
  };
  
  export const removeCurrentUser = () => {
    localStorage.removeItem('gogo_current_user');
  };
  
  export const UserRole = {
    admin: 0,
    editor: 1,
    member:2,
    electrical:3,
    assembly:4,
    superAdmin:0,
}