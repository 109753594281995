import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERTS } from './constants';

const initialState = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_ALERT:
            return [...state, payload];
        case REMOVE_ALERT:
            return state.filter(alert => alert.id !== payload);
        case CLEAR_ALERTS:
            return initialState
        default:
            return state;
    }
}