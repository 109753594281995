import {
    GET_SELECTED_SEALEDSYSTEM_ID_DATA, ERROR_SELECTED_SEALEDSYSTEM_ID_DATA, CLEAR_SELECTED_SEALEDSYSTEM_ID_DATA
} from "./constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from './constants'

const initialState = {
    loading: true,
    error: '',
    isError:false,
    heater_id:'',
    capacity:'',
    compressor_id:'',
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SELECTED_SEALEDSYSTEM_ID_DATA:
            global.heaterId_sld = payload.heaterId
            global.capacity_sld = payload.capacity
            global.compressorId_sld = payload.compressorId
            return {
                ...state,
                heater_id: payload.heaterId,
                capacity: payload.capacity,
                compressor_id: payload.compressorId,
                loading: false,
                isError:false,
                error: ''
            }
        case ERROR_SELECTED_SEALEDSYSTEM_ID_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
            }
            
        case CLEAR_SELECTED_SEALEDSYSTEM_ID_DATA:
            return initialState
        case SUBMIT_FAIL:
                return {
                    ...state,
                    result: false
                }
        case SUBMIT_SUCCESS:
                return {
                    ...state,
                    result: true
                }
        default:
            return state;
    }
}