// eslint-disable-next-line import/no-cycle
// import { UserRole } from '../helper/authHelper';

export const adminRoot = '/app';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  //role: UserRole.Admin,
};

export const isAuthGuardActive = true;