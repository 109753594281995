import {
    GET_ELECTRICAL, ERROR_ELECTRICAL, CLEAR_ELECTRICAL, GET_ELECTRICAL_MASTER_LIST, ERROR_ELECTRICAL_MASTER_LIST
} from './constants';

const initialState = {
    loading: true,
    count: 0,
    limit: 0,
    offset: 0,
    error: '',
    electricals: [],
    electricalsMaster: [],
    datafile: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload, headers } = action;
    switch (type) {
        case GET_ELECTRICAL:
            return {
                ...state,
                electricals: payload.electricals.electrical_list,
                count: payload.electricals.electrical_count,//payload.electricals.length,
                loading: false,
                limit: payload.electricals.limit,
                offset: payload.electricals.offset,
            }
        case GET_ELECTRICAL_MASTER_LIST:
            return {
                ...state,
                electricalsMaster: payload.electricals.electrical_list,
            }
        case ERROR_ELECTRICAL_MASTER_LIST:
        case ERROR_ELECTRICAL:
            return {
                ...state,
                error: payload,
                electricals: [],
                electricalsMaster:[],
                loading: false,
                count: 0,
            }
        case CLEAR_ELECTRICAL:
            return {
                ...state,
                loading: true,
                count: 0,
                error: '',
                electricals: [],
                electricalsMaster:[],
            }

        default:
            return state;
    }
}