import { LOGIN_SUCCESS, AUTH_ERROR, LOGOUT, USER_LOADED, } from './constants';
import { UserRole } from '../../helper/Utils';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    currentUser: { },//role: 1 
    error: '',
   
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                currentUser: payload,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
               // isAuthenticated: true,
                loading: false
            }
        case AUTH_ERROR:
        case LOGOUT:
            //localStorage.removeItem('token');
            global.org_role = '';
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: true,
                currentUser: { role: 1 },
                error: '',
                
            }
        default:
            return state;
    }
}