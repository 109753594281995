import api from '../../helper/api';
import { UserRole } from '../../helper/Utils';
import { USER_LOADED, USER_FAIL, LOGIN_SUCCESS, LOGOUT, LOGIN_FAIL } from './constants';
// import { setAlert } from '../../components/Alerts/actions';



//Load login User
// export const loadUser = () => async dispatch => {
//     const config = {
//         header: {
//             'Content-Type': 'application/json'
//         }
//     }
//     try {
//         const response = await api.get(`/admin`, config);
//         dispatch({
//             type: USER_LOADED,
//             payload: response.data
//         });
//         dispatch(loadUser());
//     } catch (err) {
//         const error = err.response.data
//         if (error) {
//             // dispatch(setAlert(error.msg, 'danger'));
//         }
//         dispatch({
//             type: USER_FAIL
//         });
//     }

// }


//Load login User
export const loadUser = ()=> async dispatch => {
    try {
        const resp = await api.get(`/admin`);
        global.org_role = resp.data.type;
        dispatch({
        type: USER_LOADED,
        payload: {
            id: 1,
            name: resp.data.adminID,
            role: UserRole[resp.data.type],
        }
        });
    } 
    catch (err) {
        const error = err.response.data
        if (error) {
            // dispatch(setAlert(error.msg, 'danger'));
        }
        dispatch({
            type: USER_FAIL
        });
    }
}

//Login API
export const login = (adminID, password) => async dispatch => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const body = {
        adminID,
        password
    }
    try {
        const response = await api.post(`/admin/login`, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data
        });
        dispatch(loadUser());
    } catch (err) {
        const error = err.response.data
        if (error) {
            // dispatch(setAlert(error.msg, 'danger'));
        }
        dispatch({
            type: LOGIN_FAIL
        });
    }
}

//LOGOUT 
export const logout = () => ({
    type: LOGOUT
})