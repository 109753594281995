import { GET_FILE } from "../Trips/constants";
import {
    GET_ORG_LIST,ERROR_ORG_LIST,GET_DEVICES, CLEAR_DEVICES, ERROR_DEVICES,GET_DEVICES_MASTER_LIST,ERROR_DEVICES_MASTER_LIST
} from "./constants";

const initialState = {
    loading: true,
    count: 0,
    limit: 0,
    offset: 0,
    error: '',
    devices: [],
    devicesMasterList: [],
    orgData: [],
    orgList:[],
    datafile:'',
    result:false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload ,headers} = action;
    switch (type) {
        case GET_DEVICES:
            return {
                ...state,
                devices: payload.devices.dev_list,
                count: payload.devices.dev_count,//payload.devices.length,
                loading: false,
                limit: payload.devices.limit,
                offset: payload.devices.offset,
            }
        case GET_DEVICES_MASTER_LIST:
            return {
                ...state,
                devicesMasterList: payload.devices.dev_list,
                    
            }
            case GET_ORG_LIST:
            return {
                ...state,
                orgList: payload.orgList,
                limit: payload.limit,
                offset: payload.offset,
                loading: false,
                isError:false,
                error: '',
                result:true,
            }
        case ERROR_ORG_LIST:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                orgList: [],
                count: 0,
                result:false,
            }
        case ERROR_DEVICES_MASTER_LIST:
        case ERROR_DEVICES:
            return {
                ...state,
                error: payload,
                devices: [],
                devicesMasterList:[],
                loading: false,
                count: 0,
            }
        case CLEAR_DEVICES:
            return {
                ...state,
                loading: true,
                count: 0,
                error: '',
                devicesMasterList:[],
                devices: [],
            }
            
        default:
            return state;
    }
}