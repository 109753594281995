//FILES
export const GET_FILES = 'GET_FILES';
export const ERROR_FILES = 'ERROR_FILES';


//FILES
export const UPDATE_LATEST_FILE = 'UPDATE_LATEST_FILE';
export const ERROR_UPDATE_LATEST_FILES = 'ERROR_UPDATE_LATEST_FILES';

//CLEAR FILES
export const CLEAR_FILES = 'CLEAR_FILES'