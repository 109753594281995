import {
    GET_FILES, CLEAR_FILES, ERROR_FILES,ERROR_UPDATE_LATEST_FILES,UPDATE_LATEST_FILE
} from "./constants";

const initialState = {
    loading: true,
    count: 0,
    error: '',
    files: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_FILES:
            return {
                ...state,
                files: payload.files,
                count: payload.files.length,
                loading: false
            }
        case UPDATE_LATEST_FILE:
                return {
                    ...state,
                }
        case ERROR_UPDATE_LATEST_FILES:
        case ERROR_FILES:
            return {
                ...state,
                error: payload,
                files: [],
                loading: false,
                count: 0,
            }
        case CLEAR_FILES:
            return {
                ...state,
                loading: true,
                count: 0,
                error: '',
                files: [],
            }
        default:
            return state;
    }
}