import { SUBMIT_FAIL, SUBMIT_SUCCESS } from './constants';

const initialState = {
    result: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type } = action;
    switch (type) {
        case SUBMIT_SUCCESS:
            return {
                ...state,
                result: true
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                result: false
            }
        default:
            return state;
    }
}