import { combineReducers } from 'redux';
import auth from '../Container/Login/reducer'
import toast from '../Components/Toasts/reducer'
import onBoarding from '../Container/OnBoarding/reducer'
import devices from '../Container/Devices/reducer'
import raw from '../Container/RawData/reducer'
import devInfo from '../Container/DeviceIds/reducer'
import trips from '../Container/Trips/reducer'
import datafile from '../Container/Trips/reducer'
import files from '../Container/Upload/reducer'
import sealedSystem from '../Container/SealedSystem/reducer'
import sealedSystems from '../Container/SealedSystems/reducer'
import electrical from '../Container/Electrical/reducer'
import electricals from '../Container/Electricals/reducer'
import electricalIds from '../Container/ElectricalIds/reducer'
import sealedSystemIds from '../Container/SealedSystemIds/reducer'
import liveTracking from '../Container/LiveTracking/reducer'
import deviceHealth from '../Container/DeviceHealth/reducer'
import devOrgInfo from '../Container/DeviceOwnership/reducer'
import orgInfo from '../Container/Organizations/reducer'
import devBattery from '../Container/BatteryAnalysis/reducer'
import support from '../Container/Support/reducer'
export default combineReducers({
    auth,
    toast,
    onBoarding,
    devices,
    trips,
    raw,
    files,
    datafile,
    sealedSystem,
    sealedSystems,
    electrical,
    electricals,
    liveTracking,
    devInfo,
    electricalIds,
    sealedSystemIds,
    deviceHealth,
    devOrgInfo,
    orgInfo,
    devBattery,
    support,
});