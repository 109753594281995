import {
    GET_SELECTED_ELECTRICAL_ID_DATA, ERROR_SELECTED_ELECTRICAL_ID_DATA, CLEAR_SELECTED_ELECTRICAL_ID_DATA
} from "./constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from './constants'

const initialState = {
    loading: true,
    error: '',
    isError:false,
    heater_id:'',
    imeiNo:'',
    simId: '',
    phoneNumber:'',
    compressorModuleId:'',
    batteryVoltage:'',
    OtaVersion:'',
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SELECTED_ELECTRICAL_ID_DATA:
            global.imeiNo_elec = payload.imeiNo
            global.simId_elec = payload.simId
            global.phoneNumber_elec = payload.phoneNumber
            global.compressorModuleId_elec = payload.compressorModuleId
            global.batteryVoltage_elec = payload.batteryVoltage
            global.OtaVersion_elec = payload.OtaVersion
            return {
                ...state,
                imeiNo: payload.imeiNo,
                simId: payload.simId,
                phoneNumber: payload.phoneNumber,
                compressorModuleId: payload.compressorModuleId,
                batteryVoltage: payload.batteryVoltage,
                OtaVersion: payload.OtaVersion,
                loading: false,
                isError:false,
                error: ''
            }
        case ERROR_SELECTED_ELECTRICAL_ID_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
            }
            
        case CLEAR_SELECTED_ELECTRICAL_ID_DATA:
            return initialState
        case SUBMIT_FAIL:
                return {
                    ...state,
                    result: false
                }
        case SUBMIT_SUCCESS:
                return {
                    ...state,
                    result: true
                }
        default:
            return state;
    }
}