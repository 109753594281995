import {
    GET_SELECTED_DEVICE_ID_DATA, ERROR_SELECTED_DEVICE_ID_DATA, CLEAR_SELECTED_DEVICE_ID_DATA,
    GET_SELECTED_DEVICE_LOGIN,ERROR_SELECTED_DEVICE_LOGIN
} from "./constants";
import { SUBMIT_SUCCESS, SUBMIT_FAIL } from './constants'

const initialState = {
    deviceID: null,
    devName: null,
    loading: true,
    error: '',
    metaInfo: [],
    deviceSettings: [],
    isError:false,
    result: false,
    heater_id:'',
    lastLogin:null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SELECTED_DEVICE_ID_DATA:
            global.heater_id = payload.metaInfo.heaterId;
            global.compressorModuler_id = payload.metaInfo.compressorModulerId
            global.compressor_id = payload.metaInfo.compressorId
            global.sim_id = payload.metaInfo.simId
            global.phone_number = payload.metaInfo.phoneNumber
            global.capacity = payload.metaInfo.capacity
            global.battery_voltage = payload.metaInfo.batteryVoltage
            global.imei_no = payload.metaInfo.imeiNo
            global.device_name = payload.deviceName
            global.Ota_version = payload.deviceSettings.OtaVersion
            return {
                ...state,
                deviceID: payload.deviceID,
                devName: payload.deviceName,
                metaInfo: payload.metaInfo,
                deviceSettings:payload.deviceSettings,
                loading: false,
                isError:false,
                error: ''
            }
        case GET_SELECTED_DEVICE_LOGIN:
            return {
                ...state,
                lastLogin:payload.lastLogin,
                loading: false,
                isError:false,
                error: ''
            }
        case ERROR_SELECTED_DEVICE_LOGIN:
        case ERROR_SELECTED_DEVICE_ID_DATA:
            return {
                ...state,
                isError:true,
                error: payload,
                loading: false,
                metaInfo: [],
                deviceSettings: [],
                lastLogin:null
            }
            
        case CLEAR_SELECTED_DEVICE_ID_DATA:
            return initialState
        case SUBMIT_FAIL:
                return {
                    ...state,
                    result: false
                }
        case SUBMIT_SUCCESS:
                return {
                    ...state,
                    result: true
                }
        default:
            return state;
    }
}