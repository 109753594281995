//ELECTRICAL
export const GET_SEALEDSYSTEM = 'GET_SEALEDSYSTEM';
export const ERROR_SEALEDSYSTEM = 'ERROR_SEALEDSYSTEM';
export const GET_SEALEDSYSTEM_MASTER_LIST = 'GET_SEALEDSYSTEM_MASTER_LIST';
export const ERROR_SEALEDSYSTEM_MASTER_LIST = 'ERROR_SEALEDSYSTEM_MASTER_LIST';

export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'SUBMIT_FAIL';

//CLEAR ELECTRICAL
export const CLEAR_SEALEDSYSTEM = 'CLEAR_SEALEDSYSTEM';