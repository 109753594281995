import {
    GET_DEVICEDATA_FILE,ERROR_DEVICEDATA,CLEAR_DEVICEDATA,GET_DEVICEDATA_ANALYSIS,ERROR_DEVICEDATA_ANALYSIS,
} from './constants';

const initialState = {
    loading: true,
    count: 0,
    limit: 0,
    offset: 0,
    error: '',
    deviceDataFile: '',
    deviceDataAnalysis: []

}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload, headers } = action;
    switch (type) {
        case GET_DEVICEDATA_FILE:
            return {
                ...state,
                loading: false,
                deviceDataFile:payload.deviceDataFile,
                error:''
            }
            case GET_DEVICEDATA_ANALYSIS:
            return {
                ...state,
                loading: false,
                deviceDataAnalysis:payload.deviceDataAnalysis,
                error:''
            }
        case ERROR_DEVICEDATA_ANALYSIS:
        case ERROR_DEVICEDATA:
            return {
                ...state,
                error: payload,
                loading: false,
                count: 0,
                deviceDataFile:'',
                deviceDataAnalysis : []
            }
        case CLEAR_DEVICEDATA:
            return {
                ...state,
                loading: true,
                count: 0,
                error: '',
                deviceDataFile:'',
                deviceDataAnalysis:[]
            }

        default:
            return state;
    }
}