import {
    SUBMIT_FAIL, SUBMIT_SUCCESS,GET_SEALEDSYSTEM, ERROR_SEALEDSYSTEM, CLEAR_SEALEDSYSTEM,GET_SEALEDSYSTEM_MASTER_LIST,ERROR_SEALEDSYSTEM_MASTER_LIST
    
} from './constants';

const initialState = {
    loading: true,
    count: 0,
    limit: 0,
    offset: 0,
    error: '',
    // registerAll:false,
    sealedSystems: [],
    sealedSystemsMaster: [],
    datafile: '',
    result: false,
    sealedSysGen:[],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload, headers } = action;
    switch (type) {
        case SUBMIT_SUCCESS:
            return {
                ...state,
                result: true,
                sealedSysGen: payload.sealedsystemID,
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                result: false
            }
        case GET_SEALEDSYSTEM:
            return {
                ...state,
                sealedSystems: payload.sealedSystems.sealedsystem_list,
                count: payload.sealedSystems.sealedsystem_count,//payload.sealedSystems.length,
                loading: false,
                limit: payload.sealedSystems.limit,
                offset: payload.sealedSystems.offset,
            }
        case GET_SEALEDSYSTEM_MASTER_LIST:
            return {
                 ...state,
                 sealedSystemsMaster: payload.sealedSystems.sealedsystem_list,
        }
        case ERROR_SEALEDSYSTEM_MASTER_LIST:
        case ERROR_SEALEDSYSTEM:
            return {
                ...state,
                error: payload,
                sealedSystems: [],
                sealedSystemsMaster: [],
                loading: false,
                count: 0,
            }
        case CLEAR_SEALEDSYSTEM:
            return {
                ...state,
                loading: true,
                count: 0,
                error: '',
                sealedSystems: [],
                sealedSystemsMaster: [],
            }

        default:
            return state;
    }
}